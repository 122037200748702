import { graphql } from "gatsby"
import SEO from '../../components/seo';
import { MDXRenderer } from "gatsby-plugin-mdx"

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        tags
        description
      }
    }
  }
`

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {

    // const { frontmatter, body } = data

    const post = data.mdx;

    const {title, description, tags} = post.frontmatter;

    console.log(post);
    console.log(title);
    console.log(description);
    console.log(tags)

    return (
      <>
        <SEO title={title} description={description}></SEO>
        <div className="max-w-prose p-5 mx-auto ">
            <div className="prose mx-auto" >
                <MDXRenderer>{post.body}</MDXRenderer>
            </div>
        </div>
        
        </>
    )
}